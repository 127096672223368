import { SEVERITY } from '../constants';
import { UnauthorizedError } from '../utils/custom-errors';
import {
  changeLocation,
  closeOverlay,
  closeSidebar,
  openSidebar,
  hitEscape,
  initializeSuccess,
  initializeError,
  openAppInModal,
  receiveAvatarUrl,
  reloadAppShell,
  storeMerchantId,
  appError,
  overlayError,
  updateTrialPeriod,
  notificationsAppointmentClick,
  notificationsMessageClick,
  initNotificationCounts,
  newNotificationCounts,
  notificationsAppointmentItemClick,
  notificationsAppointmentCloseClick,
  notificationsAppointmentFetchSuccess,
  notificationsAppointmentFetchError,
  openModal,
  closeModal,
  setOrganizationStatus,
  setContractDetails,
  initNotificationClientMessages,
  setupBrowserLocaleAndErrorState,
} from '../actions';
import { getLogoutUrl, getLoginUrl } from '../utils/url';
import { createFx } from '../store/fx-middleware';
import {
  hasUnauthorizedError,
  isTrialPeriodExpired,
  notificationsPopupIsOpen,
  getMerchantId,
} from '../selectors';
import { location } from '../env/browser';
import { isBrowserDeprecated } from '../utils/browser-utils';
import initI18next, { setupTranslations } from '../initializers/i18next';

import EVENT_BUS_EVENTS from '../constants/event-bus-events';
import Tracer from '../utils/tracer';
import createQueryConfig, { keys as queryKeys } from '../queries';

function hashRoute(route) {
  return route.startsWith('#');
}

async function fallbackToBrowserLocale({ action, dispatch }) {
  // eslint-disable-next-line no-undef
  const browserLocale = navigator.language || navigator.userLanguage;

  await initI18next(browserLocale);

  try {
    await setupTranslations(browserLocale);
  } catch (e) {
    await setupTranslations('zu');
  }

  dispatch(initializeError(action.meta.error));
}

const processLogErrorFx = ({
  action: {
    meta: { error },
  },
  deps: { errorLogger },
}) => {
  // we don't want to log UnauthorizedError anymore
  if (error instanceof UnauthorizedError) return;

  errorLogger.log(error);
};

export const logAppErrorFx = createFx(appError, processLogErrorFx);
export const logOverlayErrorFx = createFx(overlayError, processLogErrorFx);
export const logInitializeErrorFx = createFx(
  initializeError,
  processLogErrorFx,
);

export const fallbackToBrowserLocaleOnInitErrorFx = createFx(
  setupBrowserLocaleAndErrorState,
  fallbackToBrowserLocale,
);

export const redirectToLoginPageWhenUnauthorizedFx = createFx(
  initializeError,
  ({ getState, deps: { router } }) => {
    if (!hasUnauthorizedError(getState())) return;

    router.load(getLoginUrl(location.href));
  },
);

const extractManifestOverrideDetailsFromUrl = (url) => {
  const match = String(url).match(
    /APP_MANIFEST_URL(%3A|:)([a-z\d-]+)=([^&#]+)/,
  );
  if (!match) return [];

  const [, , appName, encodedManifestUrl] = match;

  return { appName, manifestUrl: decodeURIComponent(encodedManifestUrl) };
};

const saveManifestUrlOverride = ({ appName, manifestUrl }, sessionStorage) => {
  if (!appName || !manifestUrl) return;
  sessionStorage.setItem(`APP_MANIFEST_URL:${appName}`, manifestUrl);
};

export const storeAppManifestUrlOverwriteFx = createFx(
  changeLocation,
  ({ action, deps: { sessionStorage } }) => {
    saveManifestUrlOverride(
      extractManifestOverrideDetailsFromUrl(action.payload.url),
      sessionStorage,
    );
  },
);

export const closeOverlayFx = createFx(
  closeOverlay,
  ({ getState, deps: { router } }) => {
    const { currentOverlay } = getState();
    if (!currentOverlay.name) return;

    router.navigate('#');
  },
);

export const hitEscapeFx = createFx(hitEscape, ({ dispatch, getState }) => {
  const { currentOverlay } = getState();
  if (!currentOverlay.name) return;
  if (currentOverlay.isLoading) return;

  dispatch(closeOverlay());
});

export const storeMerchantIdFx = createFx(
  storeMerchantId,
  ({ getState, action, deps: { localStorage } }) => {
    const merchantId = action.payload;
    const { currentAccount } = getState();

    localStorage.setItem(`${currentAccount.id}:currentMerchantId`, merchantId);
  },
);

export const reloadAppShellFx = createFx(
  reloadAppShell,
  ({ deps: { router } }) => {
    router.reloadLocation();
  },
);

export const changedLanguageFx = createFx(
  'APP(wrapper)/CHANGED_LANGUAGE',
  ({ dispatch }) => {
    dispatch(reloadAppShell());
  },
);

export const changeAppWithNewAppShellVersionAvailableFx = createFx(
  changeLocation,
  ({ previousState, getState, dispatch }) => {
    const currentState = getState();

    if (!currentState.documentHeaders.hasLastModifiedChanged) return;
    if (previousState.currentApp.name === currentState.currentApp.name) return;

    dispatch(reloadAppShell());
  },
);

export const selectLocationsFx = createFx(
  'APP(locations)/SELECT_LOCATION', // selects a merchant
  async ({ dispatch, getState, action, deps: { apiClient, router } }) => {
    const { currentMerchant } = getState();
    const merchantId = action.payload.id;
    if (currentMerchant.id === merchantId) return;

    dispatch(storeMerchantId(merchantId));
    await apiClient.switchLocationOnCore(merchantId);
    router.navigate('/calendar');
    dispatch(reloadAppShell());
  },
);

export const initializeSuccessFx = createFx(
  initializeSuccess,
  async ({
    dispatch,
    action,
    deps: { apiClient, queryClient, errorLogger },
  }) => {
    const {
      currentMerchantData: { id },
      myAccountData: { email },
    } = action.payload;
    const unreadNotifications = await apiClient.notificationsV2.unread({
      merchant_id: id,
    });

    const queryConfig = createQueryConfig(queryClient, apiClient);

    queryClient.prefetchQuery(queryConfig.merchantsSearch());

    const clientMessages = isBrowserDeprecated() ? ['browser-deprecation'] : [];

    errorLogger.setContext({ email, id });
    dispatch(storeMerchantId(id));
    // TODO: apiClient.switchLocationOnCore(id);
    dispatch(receiveAvatarUrl(await apiClient.getAvatarUrl()));
    dispatch(initNotificationClientMessages(clientMessages));
    dispatch(initNotificationCounts(unreadNotifications));
  },
);

export const notificationMessageClickFx = createFx(
  notificationsMessageClick,
  async ({
    deps: { router, apiClient },
    action: { payload: messageCount },
    getState,
  }) => {
    router.navigate('#/messenger/conversations');

    if (messageCount > 0) {
      const merchantId = getMerchantId(getState());

      await apiClient.notificationsV2.deleteAllByType('message', {
        merchant_id: merchantId,
      });
    }
  },
);

const fetchAppointmentNotifications = async ({
  dispatch,
  getState,
  deps: { apiClient },
}) => {
  try {
    const merchantId = getMerchantId(getState());
    const { notifications } = await apiClient.notificationsV2.list({
      type: 'appointment',
      merchant_id: merchantId,
    });

    const decodedAppointmentNotifications = notifications.map(
      (notification) => {
        const { first_name: firstName, last_name: lastName } =
          notification.metadata.customer;

        return {
          id: notification.id,
          appointmentId: notification.appointment_id,
          appointmentType: notification.metadata.appointment_type || '',
          groupId: notification.metadata.group_id || '',
          timestamp: notification.triggered_at,
          customerName: `${firstName} ${lastName}`,
          status: notification.metadata.state,
          requestedDate: notification.metadata.starts_at,
          services: notification.metadata.services
            .map(({ name }) => name)
            .join(', '),
        };
      },
    );

    dispatch(
      notificationsAppointmentFetchSuccess(decodedAppointmentNotifications),
    );
  } catch (e) {
    // TODO add error logging probbaly as redux middleware or so
    dispatch(notificationsAppointmentFetchError());
  }
};

export const notificationsAppointmentClickFx = createFx(
  notificationsAppointmentClick,
  fetchAppointmentNotifications,
);

export const newNotificationCountsFx = createFx(
  newNotificationCounts,
  /* istanbul ignore next */
  ({ getState, dispatch, deps }) => {
    if (notificationsPopupIsOpen(getState())) {
      fetchAppointmentNotifications({ dispatch, getState, deps });
    }
  },
);

const markNotificationAsRead = async ({ apiClient, id, dispatch }) => {
  try {
    await apiClient.notificationsV2.delete(id);
  } catch (e) {
    // TODO add error logging probably as redux middleware or so
    dispatch(notificationsAppointmentFetchError());
  }
};

export const notificationsAppointmentItemClickFx = createFx(
  notificationsAppointmentItemClick,
  async ({ dispatch, action: { payload }, deps: { apiClient, router } }) => {
    const redirectPath =
      payload.appointmentType === 'appointment_group' && payload.groupId
        ? `#/wrapper/courses/${payload.groupId}`
        : `#/appointment/${payload.appointmentId}`;
    router.navigate(redirectPath);
    markNotificationAsRead({ apiClient, id: payload.id, dispatch });
  },
);

/* istanbul ignore next */
export const notificationsAppointmentCloseClickFx = createFx(
  notificationsAppointmentCloseClick,
  async ({ dispatch, action, deps: { apiClient } }) => {
    const { payload } = action;
    markNotificationAsRead({ apiClient, id: payload, dispatch });
  },
);

export const clickCloseAppFx = createFx(
  'APP(*)/CLICK_CLOSE',
  ({ dispatch }) => {
    dispatch(closeOverlay());
  },
);

export const clickCloseSidebarFx = createFx(
  'APP(*)/CLICK_CLOSE_SIDEBAR',
  ({ dispatch, deps: { router } }) => {
    router.navigate('#');
    dispatch(closeSidebar());
  },
);

/* istanbul ignore next */
export const openSidebarFx = createFx(
  'APP(*)/OPEN_SIDEBAR',
  ({ action, dispatch }) => {
    const { url } = action.payload;
    dispatch(openSidebar(url));
  },
);

export const navigateToAppFx = createFx(
  'APP(*)/NAVIGATE_TO',
  ({ action, dispatch, deps: { eventBus, locationRef = location } }) => {
    const { url } = action.payload;

    if (url) {
      const currentPath = locationRef.pathname;
      const newLocation = (
        hashRoute(url) ? `${currentPath}${url}` : url
      ).replace('//', '/');

      dispatch(changeLocation(newLocation));
      eventBus.emit(EVENT_BUS_EVENTS.NAVIGATE, {
        payload: { route: newLocation },
      });
    }
  },
);

export const openModalFx = createFx('APP(*)/OPEN_MODAL', ({ dispatch }) => {
  /* istanbul ignore next */
  dispatch(openModal());
});

export const closeModalFx = createFx('APP(*)/CLOSE_MODAL', ({ dispatch }) => {
  /* istanbul ignore next */
  dispatch(closeModal());
});

const processLogoutFx = ({ deps: { router } }) => {
  Tracer.removeUser();

  /* istanbul ignore next */
  router.load(getLogoutUrl());
};

export const logoutAppFx = createFx('APP(*)/LOGOUT', processLogoutFx);

export const logoutFx = createFx('CLICK_LOGOUT', processLogoutFx);

const processRedirectToContractPageWhenTrialEndsFx = ({
  getState,
  deps: { router, queryClient, locationRef = location },
}) => {
  const state = getState();
  const { currentOverlay, currentMerchant } = state;

  const hasChargebeeSubscription =
    currentMerchant.id &&
    queryClient.getQueryData(queryKeys.subscription.latest(currentMerchant.id));

  if (hasChargebeeSubscription) {
    return;
  }

  const currentRoute = locationRef.pathname;

  if (currentRoute.includes('contract') && !currentOverlay.name) return;

  if (isTrialPeriodExpired(state)) {
    router.navigate('/contract');
  }
};

export const redirectToContractPageOnChangeLocationFx = createFx(
  changeLocation,
  processRedirectToContractPageWhenTrialEndsFx,
);

export const redirectToContractPageOnInitializationFx = createFx(
  initializeSuccess,
  processRedirectToContractPageWhenTrialEndsFx,
);

export const updateUnreadNotificationsCountFx = createFx(
  'APP(*)/READ_NOTIFICATION',
  ({ dispatch }) => {
    dispatch(newNotificationCounts());
  },
);

export const updateTrialPeriodFx = createFx(
  'APP(*)/UPDATE_TRIAL_PERIOD',
  ({ action, dispatch }) => {
    dispatch(updateTrialPeriod(action.payload));
  },
);

export const updateAvatarFx = createFx(
  'APP(*)/UPDATE_AVATAR',
  ({ action, dispatch }) => {
    dispatch(receiveAvatarUrl(action.payload));
  },
);

export const openAppInModalFx = createFx(
  'APP(*)/OPEN_APP_IN_MODAL',
  ({ dispatch, action: { payload } }) => {
    dispatch(openAppInModal(payload));
  },
);

export const setAccountContractDetailsFx = createFx(
  initializeSuccess,
  async ({ dispatch, deps: { apiClient, queryClient } }) => {
    const queryConfig = createQueryConfig(queryClient, apiClient);
    const { organization } = await apiClient.getCurrentMerchantData();

    const contracts = await queryClient.fetchQuery(
      queryConfig.contractV2.findAll(organization.id),
    );

    const paymentSourceRequests = contracts.map(({ id }) =>
      apiClient.paymentSourceV2.findAll({
        filter: { contract: id },
      }),
    );

    const unnest = (array) => array.reduce((acc, cur) => [...acc, ...cur], []);

    const paymentSources = await Promise.all(paymentSourceRequests);

    const { source_type: defaultPaymentSourceType = null } =
      unnest(paymentSources).find((p) => p.default_source === true) || {};

    const contractDetails = {
      packages: [
        ...new Set(
          contracts.reduce(
            (acc, { packages = [] }) => [
              ...acc,
              ...packages.map((p) => p.package_code),
            ],
            [],
          ),
        ),
      ],
      states: contracts.map((c) => c.state),
      paymentSources: [
        ...new Set(unnest(paymentSources).map((s) => s.source_type)),
      ],
      defaultPaymentSourceType,
    };

    dispatch(setContractDetails(contractDetails));
  },
);

const loadOrganizationStatus = async ({
  dispatch,
  getState,
  deps: { apiClient },
}) => {
  const { currentAccount } = getState();
  const { organization, id: currentMerchantId } =
    await apiClient.getCurrentMerchantData();
  const isOwnerOrAdmin =
    currentAccount.organization_admin ||
    currentAccount.roles
      .filter(({ merchant: { id } }) => id === currentMerchantId)
      .reduce(
        (acc, { name }) => acc || ['owner', 'admin'].includes(name),
        false,
      );
  if (!isOwnerOrAdmin) {
    return;
  }

  const organizationStatus = await apiClient.organizationStatusV2.find(
    organization.id,
  );

  dispatch(setOrganizationStatus(organizationStatus));
};

export const checkOrganizationStatusFx = createFx(
  initializeSuccess,
  loadOrganizationStatus,
);

export const reloadOrganizationStatusFx = createFx(
  'APP(*)/RELOAD_ORGANIZATION_STATUS',
  loadOrganizationStatus,
);

export const notificationSMSDisplaySeverityFx = createFx(
  'NOTIFICATIONS_SMS_DISPLAY_SEVERITY',
  ({ action, deps: { localStorage } }) => {
    switch (action.payload) {
      case SEVERITY.NONE:
        localStorage.removeItem(SEVERITY.LABEL);
        break;
      case SEVERITY.WARNING:
      case SEVERITY.DANGER:
        localStorage.setItem(SEVERITY.LABEL, action.payload);
        break;
      default:
        localStorage.removeItem(SEVERITY.LABEL);
    }
  },
);
