import 'get-root-node-polyfill/implement'; // for IE / Edge
import { h, render } from 'preact';
import { QueryClient } from 'react-query';
import { createBrowserRouter } from 'react-router-dom';

import './styles/index.css';
import Router from './Router';
import Tracer from './utils/tracer';
import Logger from './utils/logger';
import FeatureRollout from './utils/feature-rollout';
import createRouter from './deprecated-router';
import initI18next, { setupTranslations } from './initializers/i18next';
import initMomentJs from './initializers/moment';
import configureApiClient, { createApiClient } from './initializers/api-client';
import getGlobalValue from './utils/get-global-value';
import createStore from './store';
import * as defaultSubscriptions from './subscriptions';
import loadApp from './app-manifest/app-loader';
import { fetchSubscription } from './utils/fetch-subscription';
import { GlobalsProvider } from './globals-context';
import {
  initializeSuccess,
  notificationSMSDisplaySeverity,
  setupBrowserLocaleAndErrorState,
} from './actions';
import { localStorage, sessionStorage } from './utils/namespaced-storage';
import { createAsyncScriptNode } from './utils/dom';
import createTrackMethod from './initializers/track-method';
import createErrorLogger from './initializers/error-logger';
import { createEventBus } from './initializers/event-bus';
import preLoadApp from './app-manifest/pre-loader';
import { values } from './utils/object';
import { NODE_ENV, SEVERITY, CELLO_PRODUCT_ID, API_ENV } from './constants';
import { location, document } from './env/browser';
import { keys as queryKeys } from './queries';

if (NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  require('preact/debug');
}

Tracer.init();
FeatureRollout.activate('api-consumption-analysis', { percentage: 30 });

const apiClient = {};
const errorLogger = createErrorLogger(getGlobalValue('Sentry'));
const router = createRouter();
const eventBus = createEventBus();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const trackMethod = createTrackMethod(queryClient, () =>
  getGlobalValue('analytics'),
);
const deps = {
  apiClient,
  trackMethod,
  router,
  sessionStorage,
  localStorage,
  errorLogger,
  eventBus,
  queryClient,
  loadApp,
};

const checkAndRedirectDouglasUrlToShore = () => {
  if (location.href.indexOf('douglas') > -1) {
    location.href = 'https://secure.shore.com';
  }
};

/* 
Since we no longer have contract with Douglas and 
we moved all the Douglas merchants to Shore contract,
So with this method we want to make sure if someone still uses
douglas url he should be redirected to Shore.
*/
checkAndRedirectDouglasUrlToShore();

const store = createStore(deps);

render(
  <GlobalsProvider store={store} tools={deps}>
    <Router create={createBrowserRouter} />
  </GlobalsProvider>,
  document.body,
  document.getElementById('asMain'),
);

// Cello script should be loaded after `render` function from above.
// Otherwise, Cello gives an error "Could not find Shadow Root"
// for the payment screen in the widget
if (CELLO_PRODUCT_ID) {
  const celloScriptSrc =
    API_ENV === 'production'
      ? 'https://assets.cello.so/app/v2/latest/cello.js'
      : 'https://assets.sandbox.cello.so/app/v2/latest/cello.js';

  document.head.appendChild(
    createAsyncScriptNode({
      src: celloScriptSrc,
      type: 'module',
    }),
  );
}

const smsDisplayNofification = (
  dispatch,
  {
    data: {
      // eslint-disable-next-line camelcase
      attributes: { current_usage },
    },
  },
) => {
  // eslint-disable-next-line camelcase
  switch (current_usage) {
    case '80%': {
      dispatch(notificationSMSDisplaySeverity(SEVERITY.WARNING));
      break;
    }
    case '100%': {
      dispatch(notificationSMSDisplaySeverity(SEVERITY.DANGER));
      break;
    }
    default: {
      dispatch(notificationSMSDisplaySeverity(SEVERITY.NONE));
    }
  }
};

(async function initialize() {
  try {
    Object.assign(
      apiClient,
      await configureApiClient(createApiClient, queryClient),
    );
    const {
      websocketListener,
      observeUnreadNotificationCount,
      ...subscriptions
    } = defaultSubscriptions;

    values(subscriptions).forEach((fn) => fn(store, deps));

    const myAccountData = await apiClient.getMyAccountData();
    const currentMerchantData = await apiClient.getCurrentMerchantData();
    const whiteLabelConfig = await apiClient.getWhiteLabelConfig();
    const sessionPermissions = await apiClient.getSessionPermissions();
    const subscription = await fetchSubscription(
      currentMerchantData.id,
      apiClient,
      queryClient,
    );

    const organizationId = myAccountData.organization.id;

    queryClient.setQueryData(queryKeys.trackingSessionMetadata, {
      has_subscription: !!subscription,
      subscription_status: subscription?.status,
      session_organization_id: organizationId,
      session_merchant_id: currentMerchantData.id,
    });

    const contracts = await apiClient.contractV2.findAll({
      filter: { organization: organizationId },
      include: ['packages', 'fees'],
    });

    const activeContract =
      contracts?.filter(
        (c) => c.state === 'accepted' || c.state === 'terminated',
      )[0] || null;

    const packages =
      activeContract?.packages.filter(
        (p) => p.category_code !== 'shore_app_marketplace',
      ) || null;

    const packageCode =
      Array.isArray(packages) && packages.length > 0
        ? packages[0].package_code
        : null;

    observeUnreadNotificationCount(store, deps, currentMerchantData.id);
    websocketListener(apiClient)(myAccountData.id)(
      'private-channel.sms_monthly_allowance.merchant_accounts',
    )('sms_monthly_allowance.v1.limit_reached')((d) => {
      smsDisplayNofification(store.dispatch, d);
    });

    // For Testing
    // const dummyData = { data: { attributes: { current_usage: '100%' } } };

    // setInterval(() => {
    //   smsDisplayNofification(store.dispatch, dummyData);
    // }, 15000);

    const { locale } = myAccountData;

    Tracer.setUser({
      merchantId: currentMerchantData.id,
      employeeId: myAccountData.employee.id,
    });

    Tracer.setGlobalContext('has_chargebee_subscription', !!subscription);

    Logger.init({
      merchantId: currentMerchantData.id,
      employeeId: myAccountData.employee.id,
    });

    await initMomentJs(locale);
    await initI18next(locale);
    await setupTranslations(locale);

    preLoadApp('search', locale, eventBus);
    preLoadApp('launchpad', locale, eventBus);

    store.dispatch(
      initializeSuccess(
        myAccountData,
        currentMerchantData,
        whiteLabelConfig,
        packageCode,
        sessionPermissions,
        subscription,
      ),
    );
  } catch (error) {
    store.dispatch(setupBrowserLocaleAndErrorState(error));
  }
})();
